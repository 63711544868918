@mixin tablet {
  @media (min-width: 495px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1024px) {
    @content;
  }
}

$text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);

html {
  font-family: "Share Tech", sans-serif;
  color: white;
  letter-spacing: 0.06em;
  height: 100%;
  width: 100%;
  position: fixed;
}

html * {
  margin: 0;
}

body {
  height: 100%;
}

a {
  color: white;
}

.container {
  display: grid;
  height: 100%;
  justify-items: center;
  align-content: space-between;
  grid-template-rows: 4px 16% 144px 40px;
}

.cloudy-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: url("./images/background.jpg") no-repeat center center fixed;
  background-size: cover;
  z-index: -1;
}

.cloudy-background::before {
  content: "";
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    #13619a 0%,
    rgba(19, 97, 154, 0.25) 47.4%,
    rgba(255, 255, 255, 0) 100%
  );
}

.top-white-bar {
  background-color: white;
  width: 274px;

  @include tablet {
    width: 322px;
  }

  @include laptop {
    width: 368px;
  }
}

//  Header

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: 40px;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 57px;
  text-align: center;
  text-shadow: $text-shadow;
  margin-bottom: 6px;

  @include tablet {
    font-size: 48px;
  }

  @include laptop {
    font-size: 56px;
  }
}

p {
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  text-shadow: $text-shadow;

  @include tablet {
    font-size: 18px;
    margin-top: 2px;
  }

  @include laptop {
    font-size: 20px;
    margin-top: 4px;
  }
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
}

.social-links {
  width: 180px;
  display: flex;
  justify-content: space-between;

  @include tablet {
    width: 210px;
  }

  @include laptop {
    width: 250px;
  }
}

.social-icon {
  width: 36px;
  text-shadow: $text-shadow;

  @include tablet {
    width: 42px;
  }

  @include laptop {
    width: 50px;
  }
}

.hire-me {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #105d94;
  border-radius: 6px;
  width: 142px;
  height: 40px;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.hire-me span {
  font-size: 19px;
  text-transform: uppercase;
  color: white;
  position: relative;
  left: 4px;
}

.hire-me img {
  width: 28.5px;
  height: 23px;
  position: relative;
  top: 3px;
}

footer {
  font-size: 12px;
  letter-spacing: 1px;
  text-shadow: $text-shadow;
  text-align: center;
  margin: 0 12px;

  @include tablet {
    font-size: 13px;
  }

  @include laptop {
    font-size: 14px;
  }
}
